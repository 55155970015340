export const environment = {
  production: true,
  KEMPER_COM: 'https://www.kemper.com',
  KEMPER_LOGO: './assets/Kemper_Logo_small.png',
  FORGOT_ID: 'https://agent.kemper.com/pi/kidm/unsecure/userForgotUserId?TARGET=',
  FORGOT_PASSW: 'https://agent.kemper.com/pi/kidm/unsecure/userForgotPassword?TARGET=',
  RIGHT_PANEL_API: 'https://www.agentinside.com/RESTWCMContentPortalService/rest/RESTWCMService/query?wcmpath=/KemperAgent/Login/LoginPageContent&pt=PT_LOGIN',
  AM_INTERNAL_AWS_HOST: 'https://frigappp0.prd.aws.kemperi.com/',
  AM_INTERNAL_BHM_HOST: 'https://frigp0.kemperi.com/',
  AM_EXTERNAL_AWS_HOST: 'https://signin.kemper.com/',
  AM_EXTERNAL_BHM_HOST: 'https://signinb.kemper.com/',
  DEFAULT_SERVICE: 'fr_authentication',
  DEFAULT_REALM: '/AEV',
  GOTO: '/openam/console',
  AM_HEADERS: {
    'Content-Type': 'application/json',
    'Accept-API-Version': 'resource=2.0, protocol=1.0',
    'Cache-Control': 'no-cache',
  }
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AMRESTService {

  constructor(
    private httpClient: HttpClient
  ) { }

  initiate (service: string, realm: string,  goto: string): any{
    // Initiate authentication call to get the callbacks
    let AM_HOST = '';
    let current_url = window.location.href;
    if (current_url.includes(environment.AM_INTERNAL_AWS_HOST)) {
      AM_HOST = environment.AM_INTERNAL_AWS_HOST;
    } else if (current_url.includes(environment.AM_INTERNAL_BHM_HOST)) {
      AM_HOST = environment.AM_INTERNAL_BHM_HOST;
      service = 'fr_b_authentication'
    } else if (current_url.includes(environment.AM_EXTERNAL_BHM_HOST)) {
      AM_HOST = environment.AM_EXTERNAL_BHM_HOST;
      service = 'fr_b_authentication'
    } else {
      AM_HOST = environment.AM_EXTERNAL_AWS_HOST;
    }
    return this.httpClient.post (AM_HOST + "am/json/authenticate?realm=" + realm + "&authIndexType=service&authIndexValue=" 
    + service + "&goto=" + goto, {}, {
      headers: new HttpHeaders(environment.AM_HEADERS),
      withCredentials: true
    });
  }


  authenticate(service: string, realm: string, goto: string, payload: any): any{
    let AM_HOST = '';
    let current_url = window.location.href;
    if (current_url.includes(environment.AM_INTERNAL_AWS_HOST)) {
      AM_HOST = environment.AM_INTERNAL_AWS_HOST;
    } else if (current_url.includes(environment.AM_INTERNAL_BHM_HOST)) {
      AM_HOST = environment.AM_INTERNAL_BHM_HOST;
      service = 'fr_b_authentication'
    } else if (current_url.includes(environment.AM_EXTERNAL_BHM_HOST)) {
      AM_HOST = environment.AM_EXTERNAL_BHM_HOST;
      service = 'fr_b_authentication'
    } else {
      AM_HOST = environment.AM_EXTERNAL_AWS_HOST;
    }
    return this.httpClient.post (AM_HOST + "am/json/authenticate?realm=" + realm + "&authIndexType=service&authIndexValue=" 
    + service + "&goto=" + goto, payload, {
      headers: new HttpHeaders(environment.AM_HEADERS),
      withCredentials: true
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer-panel',
  templateUrl: './footer-panel.component.html',
  styleUrls: ['./footer-panel.component.css']
})
export class FooterPanelComponent implements OnInit {

  kemper=environment.KEMPER_COM;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AMRESTService } from '../HttpClientServices/am-rest.service';
import { environment } from 'src/environments/environment';
import { Statement } from '@angular/compiler';
@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.css']
})
export class LeftPanelComponent implements OnInit {
  loginForm!: FormGroup;
  error = false;
  forgotID = environment.FORGOT_ID;
  forgotPassw = environment.FORGOT_PASSW;
  GOTO = environment.GOTO;
  SERVICE = environment.DEFAULT_SERVICE;
  REALM = environment.DEFAULT_REALM;
  warn = false;
  AM_HOST = '';
  message={
    error : "Login failure",
    lockout : "User Locked Out.",
    lockoutWarn : "Warning: You will be locked out after 1 more failure(s)."
  };
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private AMService: AMRESTService) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    let current_url = window.location.href;
    if (current_url.includes(environment.AM_INTERNAL_AWS_HOST)){
      this.AM_HOST = environment.AM_INTERNAL_AWS_HOST + "am/";
    } else if (current_url.includes(environment.AM_INTERNAL_BHM_HOST)) {
      this.AM_HOST = environment.AM_INTERNAL_BHM_HOST + "am/";
    } else if (current_url.includes(environment.AM_EXTERNAL_BHM_HOST)) {
      this.AM_HOST = environment.AM_EXTERNAL_BHM_HOST + "am/";
    } else {
      this.AM_HOST = environment.AM_EXTERNAL_AWS_HOST + "am/";
    }
    this.route.queryParams.subscribe(
      (params) => {
        this.GOTO = params.goto ? params.goto : this.AM_HOST;
        this.SERVICE = params.service ? params.service : environment.DEFAULT_SERVICE;
        this.REALM = params.realm ? params.realm : environment.DEFAULT_REALM;
      }
    );
    this.forgotID = environment.FORGOT_ID + encodeURIComponent(window.location.href);
    this.forgotPassw = environment.FORGOT_PASSW + encodeURIComponent(window.location.href);
  }


  ngOnInit() {

  }

  addAuthData(payload: any, formvalue: any): any {
    for (const i in payload.callbacks) {
      switch (payload.callbacks[i].type) {
        case 'PasswordCallback':
          payload.callbacks[i].input[0].value = formvalue.password;
          break;
        case 'NameCallback':
          payload.callbacks[i].input[0].value = formvalue.username;
          break;
      }
    }
    return payload;
  }

  onSubmit() {


    if (this.loginForm.invalid) {
      this.error = true;
      this.loginForm.reset();

      return;
    }


    try {
      this.AMService.initiate(this.SERVICE, this.REALM, this.GOTO).subscribe(
        (response: any) => {

          let payload = this.addAuthData(response, this.loginForm.value);
          this.AMService.authenticate(this.SERVICE, this.REALM, this.GOTO, payload).subscribe(
            (response: any) => {

              let textCallback = false
              if (response.callbacks) {
                for (let callback of response.callbacks) {
                  if (callback.type === 'TextOutputCallback') textCallback = true
                }
              }
              if (textCallback) {
                let AM_HOST = '';
                let current_url = window.location.href;
                if (current_url.includes(environment.AM_INTERNAL_AWS_HOST)) {
                  AM_HOST = environment.AM_INTERNAL_AWS_HOST;
                } else if (current_url.includes(environment.AM_INTERNAL_BHM_HOST)) {
                  AM_HOST = environment.AM_INTERNAL_BHM_HOST;
                } else if (current_url.includes(environment.AM_EXTERNAL_BHM_HOST)) {
                  AM_HOST = environment.AM_EXTERNAL_BHM_HOST;
                } else {
                  AM_HOST = environment.AM_EXTERNAL_AWS_HOST;
                }
                window.location.href = AM_HOST + 'iamservices/passwordchange/?uid=' + this.loginForm.value.username + '&goto=' + encodeURIComponent(window.location.href)
              }

              if (response.tokenId) {
                if (response.successUrl !== '/openam/console') {
                  window.location.href = this.GOTO;
                } else {
                  window.location.href = this.AM_HOST;
                }
                this.loginForm.reset();
              } 
            },
            (error: any) => {
              if (error.error.message == this.message.lockout) {
                this.router.navigate(['/error'], { queryParams: { username: this.loginForm.value.username } });

              }
              else if (error.error.message == this.message.lockoutWarn) {                
                this.warn = true;
              }
              else if(error.error.message == this.message.error) {
               this.error = true;
               if (error.error.detail.failureUrl) window.location.href = error.error.detail.failureUrl + '&uid=' + this.loginForm.value.username + '&goto=' + encodeURIComponent(window.location.href)
               this.loginForm.reset();
              }
            }
          )
        },
        (error: any) => {
          this.loginForm.reset();
        }
      );
    } catch (error) {
      this.loginForm.reset();
    
    }
  }
}
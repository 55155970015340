import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RightPanelService {

  constructor(private http:HttpClient) { }
  
  
  public rightPanelAPI(){
    return this.http.get(`${environment.RIGHT_PANEL_API}`,{responseType:'text'});
  }
}
